import React, { useContext } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'
import ds from '../theme'
import Room from '@material-ui/icons/Room'
import ArrowR from '@material-ui/icons/ArrowForward'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Body2 from './../components/Body2'
import Div from './../components/Div'
import Hr from '../components/Hr'
import Body1 from './../components/Body1'
import { css } from '@emotion/core'
import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Paragraph from '../components/Paragraph'
import ContainedButton from '../components/ContainedButton'
import OutlineButton from '../components/OutlinedButton'
import ImgServidoresExclusivos from '../images/dc-servers/servidores-exclusivos.svg'
import ImgMonitoramento from '../images/dc-servers/monitoramento.svg'
import ImgFlexibilidade from '../images/dc-servers/flexibilidade.svg'
import ImgPortalDeGerenciamento from '../images/dc-servers/portal.svg'
import ImgIpmi from '../images/dc-servers/ipmi.svg'
import ImgMonitoramentoTempo from '../images/dc-servers/tempo.svg'
import ImgInspecaoSeguranca from '../images/dc-servers/inspecao.svg'
import { withSolutions, SolutionContext } from '../components/SolutionProvider';

import { Container, mq } from '../components/styles/Layout.styles'

import { 
  styledDedicatedServers,
  header,
  IdProtectBox,
  IdProtectList,
  serverList,
  managerList
} from '../components/styles/DedicatedServers.styles'

const DcItem = ({
  title,
  children,
  id
}) => {
  const [active, setIsActive] = useContext(SolutionContext)
  const isActive = active === id
  return (
    <div
      onClick={() => setIsActive(id)}
      style={{
        padding: 24,
        backgroundColor: '#f9fafa',
        borderRadius: 16
      }}
      css={
        css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      }
    >
      <Div
        flexDirection='row'
        alignItems='center'
      >
        <div
          style={{
            width: 68,
            height: 68,
            borderRadius: '50%',
            background: ds.brand('iceBlue'),
            marginRight: ds.space(3),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Room
            style={{
              color: '#ff5800',
              fontSize: 32
            }}
          />
        </div>
        <Body1
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            lineHeight: 1.77,
            letterSpacing: 0.5
          }}
        >
          {title}
        </Body1>
      </Div>
      {children && (
        <Div
          style={{
            position: 'relative',
            opacity: isActive ? 1 : 0,
            maxHeight: isActive ? 500 : 0,
            transition: isActive ? 'max-height 2s, opacity 0.35s 0.35s' : 'max-height 0.8s 0.35s, z-index 0.7s 0.7s, opacity 0.7s',
            zIndex: isActive ? 1 : -1
          }}
        >
          <Body2
            color='black'
            variant='mediumEmphasis'
            mt={[3]}
          >
            {children}
          </Body2>
        </Div>
      )}
    </div>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledDedicatedServers}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
          >            
            <H4>
              <span>Servidores Dedicados</span><br />
              Economia & Exclusividade
            </H4>
            <Paragraph>Hardwares fisicamente isolados, de uso exclusivo da sua empresa, trazendo assim maior segurança e desempenho, além de contar com monitoramento proativo 24x7x365.</Paragraph>
          </Column>
        </Row>
        <Row
          mt={30}
          mb={46}
        >
          <Div 
            flexDirection={['column', 'column', 'unset', 'unset']}
            css={IdProtectBox}>
            <Column
              width={[1, 1, 1/2, 1/2]}
            >
              <div css={IdProtectList}>
                <ArrowR 
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    marginRight: 16
                  }}
                />
                <span>Um ou mais servidores exclusivos para a sua empresa</span>
              </div>
              <div css={IdProtectList}>
                <ArrowR 
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    marginRight: 16
                  }}
                />
                <span>Monitoramento proativo 24x7x365</span>
              </div>
              <div css={IdProtectList}>
                <ArrowR 
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    marginRight: 16
                  }}
                />
                <span>IPMI gerencie seu hardware remotamente (BIOS)</span>
              </div>
            </Column>
            <Column
              width={[1, 1, 1/2, 1/2]}
            >
              <div css={IdProtectList}>
                <ArrowR 
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    marginRight: 16
                  }}
                />
                <span>Flexibilidade na escolha do hardware</span>
              </div>
              <div css={IdProtectList}>
                <ArrowR 
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    marginRight: 16
                  }}
                />
                <span>Portal de gerenciamento dos servidores intuitivo</span>
              </div>
              <div css={IdProtectList}>
                <ArrowR 
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    marginRight: 16
                  }}
                />
                <span>Monitoramento em tempo real</span>
              </div>
              <div css={IdProtectList}>
                <ArrowR 
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    marginRight: 16
                  }}
                />
                <span>Inspeção de segurança em 5 etapas</span>
              </div>
            </Column>
          </Div>
        </Row>
        <Hr
          lineColor='lightPeriwinkle'
          maxWidth={[328, '100%', 680, 680]}
          style={{
            opacity: 0.5,
            margin: '0 auto'
          }}
        />
        <Row
          flexDirection={['column', 'row']}
          css={header}
          mt={40}
        >
          <Column
            width={[1]}
          >            
            <H4 style={{ textAlign: 'center' }}>Diferentes Necessidades,<br /> Diferentes Estruturas</H4>
            <Paragraph>Pensando na sua comodidade, a HostDime preparou três diferentes estruturas de Data Center com níveis de atuação distintos. Veja as especificidades das plataformas e confira qual estrutura se adequa melhor à aplicação e disponibilidade de investimento do seu projeto.</Paragraph>
            <H5>Entenda a diferença</H5>
          </Column>
        </Row>
        <Row
          justifyContent={'center'}
          flexDirection={['column', 'row']}
          mb={50}
        >
          <Div
            maxWidth={['100%', 422, 422]}
          >
            <DcItem
              title='DC Orlando - USA'
              id='solution01'
            >
              Estrutura ideal para quem deseja investir pouco e ter acesso a qualidade de ponta. Servidores robustos e conectividade abundante. Os serviços têm um menor preço, já que possuímos estrutura própria nos Estados Unidos, bem com o custo para aquisição de novos equipamentos é menor que no Brasil.
            </DcItem>
            <DcItem
              title='DC São Paulo - Brasil'
              id='solution02'
            >
              Perfeita para quem procura um serviço premium, com plena capacidade de operação e as melhores rotas de conexões. Servidores com máxima potência e rotas redundantes destacam esta estrutura. Além disso, toda a estrutura é 100% controlada e possui certificação internacional Tier III* e SSAE-16, o que atesta a operação das plataformas.
              <br /><br />
              *certificado pela Centurylink Comunicações do Brasil Ltda - São Paulo Data Centers DC-6, DC-7, DC-8, DC-9 e DC-10.
            </DcItem>
            <DcItem
              title='DC Nordeste - Brasil'
              id='solution03'
            >
              Melhor conectividade para o Brasil do que o Data Center de Orlando-FL. É uma excelente opção para ser a sua primeira estrutura no Brasil ou caso a sua aplicação não necessite de baixíssima latência para a região sul do país. Destaca-se pela baixa latência para outros países e latência média para todo o Brasil. 
              <br /><br />
              Data Center com Tier III Design Certification:<br />
              ISO 9001<br />
              ISO 27001<br />
              ISO 20000
            </DcItem>
          </Div>
        </Row>
        <Row
          justifyContent={'center'}
          mb={50}
        >
          <Div
            alignItems={'center'}
          >
            <OutlineButton
              type='btnOutlinedLarge'
              Link='/estrutura'
            >
              conheça nossa estrutura
            </OutlineButton>
            <div style={{ marginBottom: 16 }} />
            <ContainedButton
              type='btnContainedLarge'
              Link='/servidores-dedicados-nordeste'
            >
              consulte os preços
            </ContainedButton>
          </Div>
        </Row>
        <Hr
          lineColor='lightPeriwinkle'
          maxWidth={[328, '100%', 680, 680]}
          style={{
            opacity: 0.5,
            margin: '0 auto'
          }}
        />
        <Row
          flexDirection={['column', 'row']}
          css={header}
          mt={40}
          mb={50}
        >
          <Column
            width={[1]}
          >            
            <H4 style={{ textAlign: 'center' }} >Hardwares fisicamente isolados, de uso exclusivo para sua empresa.</H4>
          </Column>
        </Row>
        <Row
          css={serverList}
          mb={50}
        >
          <Div
            alignItems={'center'}
            flexDirection={['column', 'column', 'row']}
            textAlign={'center'}
            maxWidth={['100%', 422, 1020]}
            style={{
              margin: '0 auto'
            }}
          >
            <Column
              width={[1]}
              mb={[40, 40, 0, 0]}
            >            
              <Div
                alignItems={'center'}
              >
                <img src={ImgServidoresExclusivos} />
                <H6>Um ou mais servidores<br /> exclusivos para usa empresa</H6>
              </Div>
            </Column>
            <Column
              width={[1]}
              mb={[40, 40, 0, 0]}
            >            
              <Div
                alignItems={'center'}
              >
                <img src={ImgFlexibilidade} />
                <H6>Flexibilidade na <br />escolha do hadware</H6>
              </Div>
            </Column>
            <Column
              width={[1]}
              mb={[0]}
            >            
              <Div
                alignItems={'center'}
              >
                <img src={ImgMonitoramento} />
                <H6>Monitoramento<br />proativo 24x7x365</H6>
              </Div>
            </Column>
          </Div>
        </Row>
        <Hr
          lineColor='lightPeriwinkle'
          maxWidth={[328, '100%', 1032, 1032]}
          style={{
            opacity: 0.5,
            margin: '0 auto'
          }}
        />
        <Row
          mb={30}
          mt={49.5}
        >
          <Div 
            flexDirection={['column', 'column', 'unset', 'unset']}
            maxWidth={['100%', 422, 860]}
            alignItems={'center'}
            style={{
              margin: '0 auto'
            }}
          >
            <Column
              width={[1, 1, 1/2, 1/2]}
              mb={[30, 30, 0, 0]}
            >
              <div css={managerList}>
                <img src={ImgPortalDeGerenciamento} />
                <H6>Portal de gerenciamento dos servidores intuitivo</H6>
              </div>
            </Column>
            <Column
              width={[1, 1, 1/2, 1/2]}
            >
              <div css={managerList}>
                <img src={ImgIpmi} />
                <H6>IPMI gerencie seu hardware remotamente (BIOS)</H6>
              </div>
            </Column>
          </Div>
        </Row>
        <Row
          mb={[50, 70]}
        >
          <Div 
            flexDirection={['column', 'column', 'unset', 'unset']}
            maxWidth={['100%', 422, 860]}
            alignItems={'center'}
            style={{
              margin: '0 auto'
            }}
          >
            <Column
              width={[1, 1, 1/2, 1/2]}
              mb={[30, 30, 0, 0]}
            >
              <div css={managerList}>
                <img src={ImgMonitoramentoTempo} />
                <H6>Monitoramento em<br /> tempo real</H6>
              </div>
            </Column>
            <Column
              width={[1, 1, 1/2, 1/2]}
            >
              <div css={managerList}>
                <img src={ImgInspecaoSeguranca} />
                <H6>Inspeção de segurança em 5 etapas</H6>
              </div>
            </Column>
          </Div>
        </Row>
        <Div
          alignItems={'center'}
        >
          <ContainedButton
            type='btnContainedLarge'
            Link='/gerenciamento-dedicado'
          >
            gerenciamento dedicado
          </ContainedButton>
        </Div>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Servidores Dedicados HostDime | Segurança e Desempenho' 
        description='Servidores dedicados para seu negócio contar com hardwares fisicamente isolados, de uso exclusivo monitoramento proativo 24x7x365.' 
      />
      <PageTemplate
        title='Servidores Dedicados'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default withSolutions(StaticPage)
